// Here you can add other styles

.background {
  background-image: url('../assets/images/background.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.wrapper {
  // background-image: url('../assets/images/homebg.png');
  // background-repeat: no-repeat;
  // background-position: center center;
  // background-size: cover;
  background: radial-gradient(
    70.83% 85.87% at 34.46% 29.17%,
    #435268 0%,
    #1d3551 36.98%,
    #0d2337 66.84%,
    #002e31 100%
  );
}
.login-card {
  background-color: transparent;
  color: #cc071e !important;
  border: none !important;
}
.btn-login-button,
.btn-login-button:hover,
.btn-login-button:active,
.btn-login-button:focus,
.btn-login-button:visited {
  background-color: #000000 !important;
  color: #ffffff !important;
  border-color: #000000 !important;
}
.bg-login {
  background-color: white !important;
  color: #000000;
  opacity: 0.8;
}
.header {
  background-color: #13253a !important;
  border: none !important;
}

.sidebar {
  background-color: #0d1927 !important;
  border: none !important;
}
.breadcrumb-item::before {
  content: '' !important;
}
.footer {
  background-color: #2c2c2c !important;
  color: #ffffff !important;
  height: 3.01rem !important;
}
.app-logo {
  width: 200px;
  padding: 20px;
}

.breadcrumb-item.active {
  font-size: 24px;
  color: #ffffff;
}
.logout-icon {
  transform: rotate(180deg);
  cursor: pointer;
}

.bg-row1 {
  background-color: #2c2c2c;
  border: 1px solid #bab7b7;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
  height: 100%;
}
.colvaluewidth {
  width: 26.2%;
}
.bg-row-transparent {
  background-color: #ffffff;
  color: #2c2c2c;
  border: 1px solid #cccaca;
  text-align: center;
}
.bg-row1-col1 {
  border-top-left-radius: 10px;
}
.bg-row1-col6 {
  border-top-right-radius: 10px;
}
.round-circle {
  display: inline-block;
  margin-bottom: -12px;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  margin-left: 10px;
}

.tile-element {
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #fb7358;
  border-radius: 6px;
  border-left: 15px solid #fb7358;
  &.yellow {
    border: 2px solid #febe00;
    border-left: 15px solid #febe00;
  }
  &.green {
    border: 2px solid #1eb488;
    border-left: 15px solid #1eb488;
  }
}
.guage-alignment {
  padding-inline-start: 5px;
}
.customCard {
  .cardCount {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 70px;
    text-align: center;
    color: #555555;
  }
  .cardTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 54px;
    text-align: center;
    color: #929292;
  }
  .cardPercentage {
    font-size: 13px;
    .percentageIcon {
      width: 24px;
      height: 18.17px;
      display: inline-block;
      vertical-align: sub;
      &.upIcon {
        background: url(../assets/images/up-icon.png);
      }
      &.downIcon {
        background: url(../assets/images/down-icon.png);
      }
    }
    .percentageText {
      color: #555555;
      &.upIconText {
        color: #579c21;
      }
      &.downIconText {
        color: #e44659;
      }
    }
  }
  .cardIcon {
    width: 60px;
    height: 70.02px;
    float: right;
    img {
      width: 50px;
    }
  }
}
.leaflet-container {
  width: 100% !important;
}

.leaflet-bottom {
  display: none !important;
}
.mmr_box {
  width: 56px;
  height: 37px;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
}
.bg-red {
  background: #ea3323;
  border: 2px solid #ffffff;
}
.bg-lightgrey {
  background: #c4c9d0;
  border: 2px solid #ffffff;
}
.bg-green {
  background: #377e22;
  border: 2px solid #ffffff;
}
.bg-grey {
  background: #726f6f;
  border: 2px solid #ffffff;
}
.bg-yellow {
  background: #ffa500;
  border: 2px solid #ffffff;
}
.color-white {
  color: #ffffff;
}
.color-black {
  color: #000000;
}
.mmr_mms {
  border-radius: 19px;
  border: 1px solid #fff;
  background: linear-gradient(360deg, #fff 9.17%, rgba(217, 217, 217, 0) 100%);
}
.mmr_mms_application {
  background: linear-gradient(
      8.07deg,
      #ffffff 4.72%,
      rgba(210, 210, 210, 0.819345) 28.16%,
      rgba(217, 217, 217, 0) 88.65%
    ),
    linear-gradient(0deg, #d9d9d9, #d9d9d9), rgba(13, 25, 39, 0.2);
  // opacity: 0.6;
  border: 1px solid rgba(244, 244, 244, 0.3);
  min-height: 80vh;
  border-radius: 16px;
}
.mmr_box .mms_box {
  position: relative;
  width: 38px;
  height: 19px;
  margin: auto;
  margin-top: 14%;
  // z-index: 1;
  border-radius: 4px;
}
.card_mmr_mms {
  background: linear-gradient(360deg, #ffffff -8.58%, rgba(217, 217, 217, 0) 224.06%);
  border: 1px solid #fefefe;
  border-radius: 12px;
  height: 244px;
  min-width: 450px;
}
.card_mmr_mms > text {
  font-weight: 700 !important;
}
.dropdowntoggle_mmr_mms {
  color: white;
  background: #4a575e;
  border: #4a575e;
}
.mmr_completion {
  color: black;
  background: #cccbb6;
}
.mms_completion {
  color: black;
  background: #e3e9d9;
}
.mmr_mms_boxes {
  background: #ffffff;
  border-radius: 12px;
  color: white;
  height: 415px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  .statusBox {
    height: 345px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .statusBox::-webkit-scrollbar {
    width: 5px;
    background: #f5f1f19d;
    border-radius: 4px;
  }

  .statusBox::-webkit-scrollbar-thumb {
    background: #929191;
    border: 0.5px solid #929191;
    border-radius: 4px;
  }
}
.heading_mmr_mms_box {
  border-radius: 4px;
  background: linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  height: 49.32px;
  color: #000000;
  .card-header {
    margin-bottom: 0.5rem;
  }
}
.filterDropdownDiv {
  background: linear-gradient(360deg, #ffffff -8.58%, rgba(217, 217, 217, 0) 224.06%);
  // filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 12px;
  margin: 20px 20px;
  // background-color: #1eb488;
}
.filterDropdownRow {
  background: linear-gradient(360deg, #c7c7c7 -8.58%, rgba(217, 217, 217, 0) 224.06%);
  border: 2px solid #ffffff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 12px;
  // background-color: #377e22;
}

.chartLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border: 2px solid black;
  background: radial-gradient(
    78.1% 78.1% at 30.98% 28.96%,
    #ffffff 44%,
    #fafafa 54%,
    #ededed 64%,
    #d7d7d7 75%,
    #b8b8b8 86%,
    #b2b2b2 88%,
    #b4b4b4 94%,
    #bcbcbc 97%,
    #c9c9c9 99%,
    #d2d2d2 100%
  );
  color: #767676;
  word-break: break-all;
}
.chartLabelPercentage {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
}
.chartHeadingDiv {
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  height: 100%;
  display: flex;
}
.chartHeading {
  opacity: 0.5;
  font-family: 'HM Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  /* identical to box height, or 122% */
  text-align: center;
  text-transform: uppercase;
  color: #111010;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}
.chartLegend {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  /* identical to box height */

  color: #000000;
}
.question-icon {
  width: 20px !important;
  height: 20px !important;
}

.chart-filter-card {
  background: rgb(184, 181, 181);
  border: 1px solid #fefefe;
  border-radius: 10px;
}

.float-right {
  float: right;
}
.float-left {
  float: left;
}
.filter-container-check-box {
  background-color: #fff;
  width: 100%;
}
.filter-selecter {
  width: 90px;
}
.filter-selecter-2 {
  width: 100px;
}

.container_scatter {
  height: 290px;
  background-color: white;
  // margin: 0.01rem;
  display: flex;
  overflow: auto;
}
.red-border {
  border: #ea3323 1px solid;
}
.green-border {
  border: #377e22 1px solid;
}
.amber-border {
  border: #fb8c00 1px solid;
}
.red-bg {
  background-color: #d22526;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'HM Sans';
  padding: 4px;
}
.green-bg {
  background-color: #00853d;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 4px;
  font-family: 'HM Sans';
}
.amber-bg {
  background-color: #fb8c00;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 4px;
  font-family: 'HM Sans';
}
.red-bg-box {
  border: #ea3323 1px solid;
}
.green-bg-box {
  background-color: white;
  border: grey 1px solid;
}
.amber-bg-box {
  border: #fb8c00 1px solid;
}
.service-box {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes blink {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.4);
    filter: blur(1px);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.amber-dot {
  height: 12px;
  width: 12px;
  background-color: #fb8c00;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1px;
  border: none;
  animation: blink 1s infinite;
  cursor: pointer;
}
.red-dot {
  height: 12px;
  width: 12px;
  background-color: #ea3323;
  border-radius: 50%;
  display: inline-block;
  margin-right: 1px;
  border: none;
  animation: blink 1s infinite;
  cursor: pointer;
}

.service-box-container {
  overflow-y: auto;
  // min-width: max-content;
}
.tech-center-box {
  // min-width: max-content;
  width: 100%;
}
.container_header {
  justify-content: center;
  align-items: center;
  background-color: #ffa500;
  width: 50%;
  padding: 2px;
}

.custom-point {
  width: 10%;
  background-color: blue($color: #000000);
}
.gap-header-red {
  color: #b40000;
  font-size: 16px;
}
.gap-header-description {
  color: #555;
  font-size: 14px;
  line-height: 0.4em;
}
.gap-header-amber {
  color: #dc9f33;
  font-size: 16px;
}
.business-impact-title {
  color: #f76c2c;
  font-size: 16px;
  /* font-weight: bold;*/
}
.business-impact-description {
  color: #555555;
  font-size: 14px;
  // line-height: .4em;
  white-space: pre-line;
}
.gaps-identified-title {
  color: #0277c2;
  font-size: 16px;
  margin-left: -6px;
  /*font-weight: bold;*/
}
.gaps-identified-description {
  color: #555555;
  font-size: 14px;
  // white-space: pre-line;
}
.gap-impacts-th-padding {
  padding-left: 18px;
}
.gap-impact-hyperlink {
  text-decoration: Underline blue;
  cursor: pointer;
  color: #2718af;
}
.gap-impacts-th-border {
  border: 1px solid #cccccc;
}
.service-title {
  color: #312e2e;
  font-size: 24px;
  font-weight: bold;
}
.service-owner {
  color: #312e2e;
  font-size: 16px;
  font-weight: bold;
}
.service-description {
  color: #555555;
  font-size: 14px;
  line-height: normal;
  // white-space: 4px;
}
.box-red {
  background: linear-gradient(#ea3323, #ea3323) 100% 100%,
    linear-gradient(#ea3323, #ea3323) 100% 100%, #fff;
  padding: 0px;
  background-size: 20px 5px, 5px 20px;
  background-origin: content-box;
  background-repeat: no-repeat;
}
.box-green {
  background: linear-gradient(#377e22, #377e22) 100% 100%,
    linear-gradient(#377e22, #377e22) 100% 100%, #fff;
  padding: 0px;
  background-size: 20px 5px, 5px 20px;
  background-origin: content-box;
  background-repeat: no-repeat;
}
.box-amber {
  background: linear-gradient(#fb8c00, #fb8c00) 100% 100%,
    linear-gradient(#fb8c00, #fb8c00) 100% 100%, #fff;
  padding: 0px;
  background-size: 20px 5px, 5px 20px;
  background-origin: content-box;
  background-repeat: no-repeat;
}
// .gap-container{
//   //display: flex;
//   //overflow: auto;
//   // float: right;
//   // position: absolute;
// }
.gap-wrapper {
  overflow-y: auto;
  height: 530px;
}
.service-outer-box {
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;

  // height: 100%;
  // height: -moz-available;          /* WebKit-based browsers will ignore this. */
  // height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  // height: fill-available;
}
.border-left-blank {
  border-left: 1px solid transparent !important;
}
.border-top-blank {
  border-top: 1px solid transparent !important;
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -5px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 5px; /* gutter size */
  background-clip: padding-box;
  //column-gap: 10px;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  //background: grey;
  background-color: white;
  margin-bottom: 5px;
}
.swal2-title {
  font-size: 20px !important;
}
.gaps-buisness-heading-title {
  color: #0277c2;
  font-size: 24px;
  margin-left: -6px;
  /*font-weight: bold;*/
}
.form-check-input {
  border: 1px solid #5a5b5c !important;
}
