.sidebar {
  height: 100%;
}
.sidebar-brand {
  background-color: #0d1927;
  overflow-x: hidden;
}
.sidebar-toggler {
  background-color: #0d1927 !important;
  width: auto !important;
}
.nav-icon {
  height: 35px !important;
  width: 35px !important;
}
.sidebar-nav .nav-link,
.sidebar-nav svg.nav-icon {
  color: rgba(255, 255, 255, 0.4);
}
.sidebar-nav .nav-link.active {
  background-color: #070d15 !important;
  color: #ffffff !important;
}
.sidebar-nav {
  line-height: 50px;
  font-size: 16px;
}
.sidebar-nav .nav-link.active .nav-icon {
  color: #ffffff;
}

.sidebar-nav .nav-link .nav-icon:hover {
  color: #ffffff;
}
.sidebar-logo {
  height: 50px;
  width: 50px;
}
.sidebar-icon {
  height: 25px !important;
  width: 100% !important;
  margin: 0px !important;
}

.sidebar-nav .nav-link:hover {
  color: #ffffff !important;
  background-color: #0d1927;
}
.bt-mmr-guidelines-icon {
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -360px -80px;
  height: 21px;
  width: 21px;
}
.bt-mmr-guidelinesList-icon {
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -417px -80px;
  height: 21px;
  width: 21px;
}
.sidebar-nav .nav-link.active .bt-mmr-guidelinesList-icon,
.sidebar-nav .nav-link:hover .bt-mmr-guidelinesList-icon {
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -417px -13px;
  height: 21px;
  width: 21px;
}
.sidebar-nav .nav-link.active .bt-mmr-guidelines-icon,
.sidebar-nav .nav-link:hover .bt-mmr-guidelines-icon {
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -360px -13px;
  height: 21px;
  width: 21px;
}


.bt-health-icon {
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: 2822px -75px;
}

.sidebar-nav .nav-link.active .bt-health-icon,
.sidebar-nav .nav-link:hover .bt-health-icon {
  // background: url('../brand/btHealthWhite.png') no-repeat;

  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: 774px -10px;
}

.it-metrics-icon {
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: 2766px -75px;
}
.sidebar-nav .nav-link.active .it-metrics-icon,
.sidebar-nav .nav-link:hover .it-metrics-icon {
  // background: url('../brand/metricsWhite.png') no-repeat;
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: 722px -10px;
}
.settings-icon {
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: 2595px -75px;
}
.sidebar-nav .nav-link.active .settings-icon,
.sidebar-nav .nav-link:hover .settings-icon {
  // background: url('../brand/settingsWhite.png') no-repeat;
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: 549px -8px;
}
.peak-season-icon {
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: 2710px -75px;
}

.sidebar-nav .nav-link.active .peak-season-icon,
.sidebar-nav .nav-link:hover .peak-season-icon {
  // background: url('../brand/peakSeasonWhite.png') no-repeat;
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: 664px -10px;
}
.mmr-mms-icon {
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: 130px 132px;
}
.sidebar-nav .nav-link.active .mmr-mms-icon,
.sidebar-nav .nav-link:hover .mmr-mms-icon {
  // background: url('../brand/peakSeasonWhite.png') no-repeat;
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: 178px 132px;
}
.biz-metrics-icon {
  // background: url('../brand/bizTechMetricsGrey.png') no-repeat;
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: 2652px -75px;
}
.sidebar-nav .nav-link.active .biz-metrics-icon,
.sidebar-nav .nav-link:hover .biz-metrics-icon {
  // background: url('../brand/bizTechMetricsWhite.png') no-repeat;
  background: url('../assets/images/svgs/spriteSVGs.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: 606px -8px;
}
.smile-icon {
  background: url('../assets/images/svgs/tech_center_sprite.svg');
  display: inline-block;
  vertical-align: middle;
  background-position: -618px -70px;
  justify-content: center;
  position: relative;
  top: 10%;
  left: 49%;
}
.smile-p{
 margin-left: -18px;

}
// Path: tech center icon
.icon-organisational {
  background: url('../assets/images/svgs/tech_center_sprite.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -724px -63px;
}
.icon-portfolio {
  background: url('../assets/images/svgs/tech_center_sprite.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -784px -63px;
}
.icon-support {
  background: url('../assets/images/svgs/tech_center_sprite.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -146px -63px;
}
.icon-sales {
  background: url('../assets/images/svgs/tech_center_sprite.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -380px -63px;
}
.icon-supply {
  background: url('../assets/images/svgs/tech_center_sprite.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -530px -62px;
}
.icon-ai {
  background: url('../assets/images/svgs/tech_center_sprite.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -969px -63px;
}
.icon-core {
  background: url('../assets/images/svgs/tech_center_sprite.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -226px -63px;
}
.icon-security{
  background: url('../assets/images/svgs/tech_center_sprite.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -456px -62px;

}
.icon-engineering{
  background: url('../assets/images/svgs/tech_center_sprite.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -300px -63px;

}
.icon-operations{
  background: url('../assets/images/svgs/tech_center_sprite.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -64px -63px;

}
.icon-grouphm{
  background: url('../assets/images/svgs/tech_center_sprite.svg');
  display: inline-flex;
  vertical-align: middle;
  background-position: -835px -63px;
}
.icon-techcenter-all{
  height: 40px;
  width: 30px;
  padding: 2px;
  padding-bottom: 2px;
  //margin-left: -1px;
  align-items: center;
  justify-content: center;
  // padding-bottom: 4px;
  border-radius: 0 0 5px 0px;
}

