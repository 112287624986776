// .bg-dot-com-common {
//   color: #ffffff;
//   height: 70px;
// }
// .bg-store-common {
//   color: #ffffff;
//   height: 40px;
// }
.common-box-dot-com {
  font-style: normal;
  font-weight: $dot-com-weight;
  font-size: $dot-com-font-size;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 25%);
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
}
.bg-dot-com {
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
}
// .flowImageAlign {
//   bottom: 20px;
//   position: absolute;
//   height: 24%;
// }
.flexBootstrapBtn .btn-group {
  display: flex;
}

.flexBootstrapBtn .accordion-flush .accordion-item {
  min-width: 200px;
}
// .bg-dot-com-green-box {
//   background-color: $green;
//   border: 2px solid $green-500;
//   color: #fff;
// }
// .bg-dot-com-amber-box {
//   background-color: $amber;
//   border: 2px solid $amber-500;
//   color: #000;
// }
// .bg-dot-com-red-box {
//   background-color: $red;
//   border: 2px solid $red-500;
//   color: #000;
// }

.bg-store-transparent-box {
  background: rgba(0, 0, 0, 0.1);
}
// .home-delivery-image {
//   float: right;
// }
// .header-dot-com {
//   font-weight: 700;
//   font-size: $dot-com-font-size;
//   background: rgba(0, 0, 0, 0.2);
//   border: none !important;
//   color: #ffffff;
// }

.bg-dot-com-customer-experience,
.bg-dot-com-order-fulfilment,
.bg-dot-com-order-placement,
.dot-com-orderplacement-seq6 {
  height: 35px;
}
.bg-dot-com-order-placement {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}
.bg-dot-com-order-fulfilment {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}
// .dot-com-orderplacement-seq1,
// .dot-com-orderfulfilment-seq1 {
//   height: 125px;
// }

// .dot-com-orderplacement-seq2 {
//   height: 150px;
// }

.peakSeason {
  // .headerCardContainer {
  //   padding: 0px;
  //   margin-bottom: 15px;
  // }
  // .headerCard {
  //   border-radius: 5px;
  //   border: none;
  //   .card-body {
  //     padding: 12px 20px 5px 20px;
  //   }
  //   .headerText {
  //     font-style: normal;
  //     font-size: 22px;
  //     line-height: 32px;
  //     color: #ffffff;
  //     img {
  //       width: 48px;
  //       height: 48px;
  //       margin-right: 32px;
  //     }
  //   }
  // }
  .countDispaly {
    float: right;
    margin-top: 1%;
    .countCard {
      .card {
        border: none;
        border-radius: 5px;
        box-shadow: 0px 0px 4px rgba(253, 253, 253, 0.25);
        height: 30px;
        width: 114px;
        text-align: right;
        &.nostores {
          background: linear-gradient(100.38deg, #4abee2 0%, #21748e 100%);
        }
        &.total {
          background: linear-gradient(100.38deg, #b989e9 0%, #541b8c 100%);
        }
        &.critical {
          background: linear-gradient(100.38deg, #910e1d 0.67%, rgba(145, 14, 29, 0) 154.09%);
        }
        &.warning {
          background: linear-gradient(100.38deg, #fbf08d 0%, #72691c 100%);
        }
        &.ok {
          background: linear-gradient(157.08deg, #40b895 1.83%, rgba(37, 77, 65, 0.8) 98.95%);
        }
        .card-body {
          padding: 0 10px 0px 10px;
          .countCardText {
            font-style: normal;
            font-size: $dot-com-font-size;
            line-height: 32px;
            color: #ffffff;
          }
        }
      }
    }
    .countLabel {
      font-style: normal;
      font-size: $dot-com-font-size;
      line-height: 23px;
      color: #ffffff;
      margin-bottom: 0px;
    }
  }
  .peakSeasonTabs {
    display: block;
    .customTab {
      background: rgba(0, 0, 0, 0.4);
      border-radius: 50px;
      max-width: 194px;
      &.nav-tabs {
        --cui-nav-tabs-border-width: 0px;
        .nav-item {
          .nav-link {
            font-style: normal;
            font-weight: 300;
            font-size: $dot-com-font-size;
            color: #ffffff;
            cursor: pointer;
            margin: 5px;
            width: 87px;
            height: 30px;
            line-height: 13px;
            text-align: center;
            &.active {
              background: #243e5f;
              border-radius: 50px;
              color: #ffffff;
              cursor: pointer;
            }
          }
        }
      }
    }
    .tab-content {
      padding: 0;
      margin-top: -15px;
      .comView {
        background: rgba(0, 0, 0, 0.2);
        margin: 25px 0px 15px 0px;
      }
    }

    .mapViewContainer {
      .mapView {
        background: #ecfcff;
        margin: 5px 0px 15px 0px;
      }
    }
    .treeViewContainer {
      padding: 0px;
      margin-top: 38px;
      .treeView {
        height: 100%;
        background: linear-gradient(
          169.58deg,
          #3aa5c6 0%,
          #1f5f7e 25.52%,
          #0c2f53 52.48%,
          #06213d 76.56%,
          #041322 100%
        );
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        border: 1px solid #797979;
        margin: 0px;
        .card-body {
          padding: 0px;
          padding-top: 10px;
          .customTabTreeView {
            background: rgba(0, 0, 0, 0.7);
            border-radius: 50px;
            max-width: 223px;
            margin-left: 10px;
            &.nav-tabs {
              --cui-nav-tabs-border-width: 0px;
              .nav-item {
                .nav-link {
                  font-style: normal;
                  font-weight: 300;
                  font-weight: $dot-com-weight;
                  font-size: $dot-com-font-toogle-size;
                  color: #ffffff;
                  cursor: pointer;
                  margin: 5px;
                  width: 101px;
                  height: 30px;
                  line-height: 13px;
                  text-align: center;
                  &.active {
                    background: #243e5f;
                    border-radius: 50px;
                    color: #ffffff;
                    cursor: pointer;
                  }
                }
              }
            }
          }

          .tab-content {
            margin-top: 0px;
            .treeViewTabCointainer {
              padding: 10px;
              .treeView-body {
                overflow: auto;
                max-height: 463px;
                white-space: nowrap;
                ul {
                  list-style: none;
                  padding-left: 20px;
                  &:first-child {
                    padding: 10px;
                    margin-bottom: 0;
                  }
                  .labelText {
                    font-style: normal;
                    font-weight: $dot-com-label-weight;
                    font-size: $dot-com-label-font-size;
                    line-height: 23px;
                    color: #efefef;
                    div {
                      margin-bottom: 10px;
                    }
                    .collapseIcon {
                      cursor: pointer;
                      padding: 3px;
                    }
                    .countryText {
                      font-size: $dot-com-tree-font-size;
                      line-height: 26px;
                    }
                    .countryCodeText {
                      font-size: $dot-com-label-font-size;
                      line-height: 26px;
                      color: #e8e8e8;
                      font-weight: 400;
                      &.warning {
                        color: rgb(229, 146, 0);
                      }
                    }
                    .productHeadingText {
                      font-size: $dot-com-font-size;
                      line-height: 26px;
                    }
                    .dot {
                      height: 26px;
                      font-size: 14px;
                      width: 26px;
                      border-radius: 50%;
                      text-align: center;
                      display: inline-block;
                      color: #ffffff;
                      margin-left: 11px;
                      &.critical {
                        background: $gradient-red;
                        border: 2px solid $gradient-red-500;
                      }
                      &.warning {
                        background: $gradient-amber;
                        border: 2px solid $gradient-amber-500;
                      }
                    }
                  }
                  li {
                    padding-top: 2px;
                  }
                }
                &::-webkit-scrollbar {
                  height: 4px;
                  width: 6px;
                }
                /* Track */
                &::-webkit-scrollbar-track {
                  background: #8a8a8a;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                  background: #fb7358;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                  background: #616161;
                }
              }
            }
          }
          .treeViewTabBorder {
            margin: 0px;
            border: 1px solid #797979;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}

.mapView {
  margin-top: 17px;
  background: transparent;
  border: none !important;
  width: 100%;
}
.noContent {
  border: none !important;
}
// .dashed-border-dot-com {
//   border-color: #d8dbe0;
//   border-style: none dashed none none;
// }
// .big-arrow-dot-com {
//   display: flex;
//   justify-content: center;
//   text-align: center;
//   align-items: center;
//   z-index: 9;
//   position: absolute;
//   top: 30%;
// }
// .orderdetailarrow-column {
//   width: 3% !important;
// }
// .orderdetail-column {
//   width: 12.2% !important;
// }
// .order-arrow-dot-com {
//   display: flex;
//   justify-content: center;
//   text-align: center;
//   align-items: center;
//   z-index: 9;
// }
// .footer-line-dot-com {
//   display: flex;
//   justify-content: center;
//   text-align: center;
//   align-items: center;
//   z-index: 9;
//   position: absolute;
//   top: 96%;
// }

// .bg-dot-com-menu-modal {
//   box-shadow: 0px 0px 10px 0px #00000040;
//   height: 40px;
//   font-size: $dot-com-font-size;
//   line-height: 20px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
// }
// .accordion-button-green-dot-com .accordion-button {
//   background-color: $green;
//   border: 2px solid $green-500;
//   color: #fff;
//   line-height: 20px;
//   border-radius: 5px !important;
//   box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 25%);
// }
// .accordion-button-amber-dot-com .accordion-button {
//   background-color: $amber;
//   border: 2px solid $amber-500;
//   color: #000;
//   line-height: 20px;
//   border-radius: 5px !important;
//   box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 25%);
// }
// .accordion-button-red-dot-com .accordion-button {
//   background-color: linear-gradient(100.38deg, #910e1d 0.67%, rgba(145, 14, 29, 0) 154.09%);
//   border: 2px solid #813039;
//   color: #000;
//   line-height: 20px;
//   border-radius: 5px !important;
//   box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 25%);
// }

// .accordion-button-red-dot-com .accordion-button:not(.collapsed) {
//   color: #000;
//   box-shadow: 0px 0px 10px 0px #00000040;
// }
// .accordion-button-green-dot-com .accordion-button:not(.collapsed) {
//   color: #fff;
//   box-shadow: 0px 0px 10px 0px #00000040;
// }
// .accordion-button-amber-dot-com .accordion-button:not(.collapsed) {
//   color: #000;
//   box-shadow: 0px 0px 10px 0px #00000040;
// }

// .modal-dot-com-order-placement {
//   position: absolute;
//   margin-left: 40%;
//   padding-top: 80px;
// }
// .modal-dot-com-order-placement-col2 {
//   position: absolute;
//   padding-top: 200px;
//   margin-left: 10%;
// }
// .modal-dot-com-customer-experience {
//   position: absolute;
//   padding-top: 100px;
//   margin-left: 23%;
// }
// .modal-dot-com-order-placement-col2 .accordion-item,
// .modal-dot-com-order-fulfilment-col2 .accordion-item,
// .modal-dot-com-order-placement .accordion-item,
// .modal-dot-com-order-fulfilment .accordion-item,
// .modal-dot-com-customer-experience .accordion-item {
//   border: none;
// }

// .modal-dot-com-order-placement .modal-dialog,
// .modal-dot-com-order-fulfilment .modal-dialog,
// .modal-dot-com-customer-experience .modal-dialog {
//   margin: 0%;
// }
// .modal-dot-com-order-fulfilment {
//   position: absolute;
//   margin-left: 52%;
//   padding-top: 80px;
// }
// .modal-dot-com-order-fulfilment-col2 {
//   position: absolute;
//   margin-left: 33%;
//   padding-top: 100px;
// }
.modal-content {
  width: 100%;
}

.box-style-common-dot-com {
  position: relative;
  z-index: 1100;
}
.kpi-type-dot-com-common {
  font-style: normal;
  font-weight: $dot-com-weight;
  font-size: $dot-com-font-size;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

// .bg-row-customer-experience-box {
//   background-color: white;
//   border-radius: 5px;
// }

// .sideIconFilter {
//   position: absolute;
//   right: 0;
//   top: 0;
//   margin: 5px;
// }

// .hideSidebarIcon {
//   margin: 10px 0 0 2px;
//   float: left;
//   width: 10%;
// }

.toggleTreeView {
  top: 5px;
  position: absolute;
  right: 1px;
  color: white;
}
.toggleTreeViewLeft {
  margin-right: 10px;
  margin-top: 50px;
  cursor: pointer;
}
.toggleTreeViewRight {
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
// .accordion-button-green-dot-com .accordion-button::before,
// .accordion-button-green-dot-com .accordion-button::after {
//   background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
// }

.bg-dot-com-gradient-green-box {
  background: $gradient-green;
  border: 2px solid $gradient-green-500;
  color: #fff;
}
.bg-dot-com-gradient-amber-box {
  background: $gradient-amber;
  border: 2px solid $gradient-amber-500;
  color: #000;
}
.bg-dot-com-gradient-red-box {
  background: $gradient-red;
  border: 2px solid $gradient-red-500;
  color: #fff;
}
.accordion-button-gradient-green-dot-com .accordion-button {
  background: $gradient-green;
  border: 2px solid $green-500;
  color: #fff;
  line-height: 20px;
  height: 40px;
  border-radius: 5px !important;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 25%);
}
.accordion-button-gradient-amber-dot-com .accordion-button {
  background: $gradient-amber;
  border: 2px solid $amber-500;
  color: #000;
  line-height: 20px;
  height: 40px;
  border-radius: 5px !important;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 25%);
}
.accordion-button-gradient-red-dot-com .accordion-button {
  background: $gradient-red;
  border: 2px solid #813039;
  color: #fff;
  line-height: 20px;
  height: 40px;
  font-size: $dot-com-font-size;
  border-radius: 5px !important;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 25%);
}

.center-div-dot-com {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000;
  // border: 2px solid #000;
  // background-color: #fff;
  // border-radius: 3px;
}

.ellipse {
  border: 1px solid #08060d;
  height: 110px;
  width: 135px;
  border-radius: 50%;
  background: #dddddd;
}

.visitors-value-circle-dot-com {
  cursor: pointer;
  background: linear-gradient(212.34deg, rgba(17, 30, 149, 0.8) 12.67%, rgba(0, 0, 0, 0.8) 82.67%);
  box-shadow: 0px 0px 4px rgba(134, 134, 134, 0.25), 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-size: 15px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 70px;
  height: 70px;
}

.icons-dot-com {
  background: linear-gradient(204.79deg, #2a2956 8.67%, #030000 84.78%);
  transform: matrix(-0.95, 0.2, 0.31, 0.98, 0, 0);
}
.visitors-value-circle-dot-com .start-100 {
  left: 50% !important;
}
.cart-value-circle-dot-com .start-0 {
  left: 50% !important;
}

.recharts-legend-item-text {
  font-size: 12px;
}

.gradient-box-dot-com {
  background: rgba(0, 0, 0, 0.4);
}

.speed-value-circle-dot-com {
  background: linear-gradient(169.94deg, rgba(67, 80, 200, 0.8) 8.7%, rgba(0, 0, 0, 0.8) 90.06%);
  box-shadow: 0px 0px 4px rgba(134, 134, 134, 0.25), 0px 2px 4px rgba(0, 0, 0, 0.25);
  transform: matrix(0.49, -0.82, 0.97, 0.42, 0, 0);
  font-size: 15px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 50px;
  height: 50px;
}
.cart-value-circle-dot-com {
  background: linear-gradient(
    144.05deg,
    rgba(143, 144, 150, 0.8) 17.61%,
    rgba(0, 0, 0, 0.8) 81.46%
  );
  box-shadow: 0px 0px 4px rgba(134, 134, 134, 0.25), 0px 2px 4px rgba(0, 0, 0, 0.25);
  transform: matrix(0.21, 0.97, 1, -0.15, 0, 0);
  font-size: 15px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 60px;
  height: 60px;
}
// .bg-store-black-box {
//   background: linear-gradient(180deg, #6fe0c5 0%, #ffffff00 100%);
//   margin: 10px;
//   color: #fff;
//   //margin-bottom: 3rem;
//   border: 1px solid #6fe0c5;
// }
.common-visitors-box-dot-com {
  font-style: normal;
  font-weight: $dot-com-weight;
  font-size: $dot-com-font-size;
  display: flex;
  // box-shadow: 0px 3px 10px rgb(0 0 0 / 25%);
  border-radius: 5px;
}

.recharts-legend-item {
  height: 14px !important;
  display: flex !important;
  color: #ffffff;
}
.recharts-legend-wrapper {
  bottom: 0px !important;
  right: -50px !important;
}

.recharts-default-legend {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
// .Order-spliter {
//   color: #142b43;
//   width: 90%;
//   margin: auto;
// }

.metricHeader {
  display: flex;
}
.cardBackground {
  background: rgba(159, 159, 159, 0.4);
  color: white;
  width: 24%;
}
@media only screen and (max-width: 480px) {
  .cardBackground {
    width: 100%;
    margin-top: 5px;
  }

  .metricHeader {
    display: none;
  }
}
// .totalMetricCard {
//   background: rgba(159, 159, 159, 0.4);
//   color: white;
//   height: 8rem;
//   width: 100%;
//   border-radius: 5px;
// }

// .totalMetricContainer {
//   display: inline-flex;
//   justify-content: space-around;
//   margin-bottom: 1rem;
// }

.pillMetric {
  background: #d9d9d9;
  min-width: 3rem;
  height: 1.5rem;
  border-radius: 25px;
  padding-left: 4px;
  display: flex;
  justify-content: space-between;
}

.red-arrowCircleBackground {
  background: #a24330;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  float: right;
  padding-left: 5px;
  align-items: center;
  display: flex;
}

.green-arrowCircleBackground {
  background: #40b895;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  float: right;
  padding-left: 5px;
  align-items: center;
  display: flex;
}
// .totalMetricSplitter {
//   border: 1px solid;
//   margin-right: 1rem;
//   margin-left: 1rem;
// }

.metricData {
  display: flex;
  justify-content: space-between;
}

.metricBlock {
  width: auto;
}

.metricInfo {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.metricCount {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 15px;
}

.col-sm-3.px-2.py-2.cardBackground.evenCards {
  border-right: 1px solid grey;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.col-sm-3.px-2.py-2.cardBackground.oddCards {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.overallMetric {
  margin: auto;

  .col-sm-3 {
    width: 24%;
  }

  .metricInfo {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }

  .metricCount {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 15px;
  }

  .evenCards {
    border-right: 1px solid grey;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .oddCards {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }

  .cardSpace {
    margin-right: 4% !important;
  }
}

.positiveMetric {
  color: rgba(37, 77, 65, 0.8);
  font-size: 12px;
  font-weight: 600;
}

.negativeMetric {
  color: #a24330;
  font-size: 12px;
  font-weight: 600;
}
.vertically-center-div-dot-com {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.common-customer-experience-box-dot-com {
  font-style: normal;
  font-weight: $dot-com-weight;
  font-size: $dot-com-font-size;
  line-height: 23px;
  height: 200px;
  border-radius: 5px;
  cursor: pointer;
}
.brand-status-dot-com {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
}

.critical-status-dot-com {
  background: #d7142b;
}
.ok-status-dot-com {
  background: #0bac1c;
}
.warning-status-dot-com {
  background: #fbf08d;
}
.status-circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.summary-card-dot-com {
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff;
}

.summary-text-dot-com {
  font-size: 16px;
}
.summary-max-count {
  font-size: 15px;
  color: #0bac1c;
}
.summary-number-dot-com {
  font-size: 20px;
}
.recharts-text {
  font-size: 11px;
}

// .customerAction {
//   background: rgba(96, 96, 96, 0.5);
//   border-radius: 7px;
//   height: 100%;
// }
// .orderRegulation {
//   background: rgba(96, 96, 96, 0.5);
//   border-radius: 7px;
// }
// .delivery {
//   background: rgba(96, 96, 96, 0.5);
//   border-radius: 7px;
// }

.hideAfterArrow::after {
  border: none !important;
}

.hideAfterArrow:focus,
.hideAfterArrow:hover {
  border: none !important;
}

.externalChannel {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 7px;
  color: #ffffff;
  font-weight: 400;
  line-height: 22px;
  font-size: 18px;
  margin-left: 0px;
  margin-right: 3px;
  .externalChannelHeading {
    font-size: 16px;
  }
  .topBrandHeading {
    font-size: 15px;
  }
  .orderCountContainer {
    .orderCount {
      font-size: 36px;
      vertical-align: middle;
      margin-left: 15px;
    }
  }
  .logoContainer {
    // width: 87.65px;
    height: 35px;
    background: #9a9a9a;
    border-radius: 2px;
    .card-body {
      padding: 3px;
      // img {
        // width: 77.96px;
      // }
    }
  }
  .logoTitle {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .logoCount {
    // width: 87.65px;
    text-align: center;
    margin-top: 18px;
  }
}

// .dropdown-toggle {
  //white-space: inherit !important;
// }
// .customerorder {
//   background: rgba(96, 96, 96, 0.5);
//   border-radius: 7px;
// }

.tree-view-amber-text-store {
  color: $amber;
  border: 1px solid $amber;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 2px;
}
.tree-view-red-text-store {
  color: $red;
  border: 1px solid $red;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 2px;
}

// .tree-view-green-text-store {
//   color: $green;
// }
.ce-view-align {
  text-align: left;
  color: white;
}
.storeDetailsContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 11px;

  .storeDetailsItem {
    margin: 4px;
    flex: 1 1 40%;
    background: #ffffff;
    opacity: 0.8;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .cardTitle {
    font-weight: 600;
    font-size: 11px;
    line-height: 23px;
    color: #1b1a1a;
  }

  .cardValue {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    color: #1b1a1a;
  }
  .text-end {
    font-size: 21px !important;
  }
}

.bg-group-data-dot-com {
  background: rgba(0, 0, 0, 0.3);
  border: 0.5px solid #69baa6;
  border-radius: 9px;
}

.dropdown-menu {
  --cui-dropdown-min-width: 16rem;
}
.critical-text-dot-com,
.critical-text-dot-com:hover,
.critical-text-dot-com:focus,
.critical-text-dot-com:focus-visible,
.critical-text-dot-com:active {
  background: none;
  border: none;
  box-shadow: none;
}

.popover-body {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  border-radius: 5px;
}
.collapedView {
  height: 30px;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(22, 22, 22, 0.2);
  box-shadow: 0px 3px 4px rgb(0 0 0 / 25%);
  backdrop-filter: blur(5px);
  border-radius: 7px;
  color: white;
  text-align: center;
  * {
    cursor: pointer;
  }
  svg {
    background: none !important;
  }
}

.expandedView {
  max-height: 150px;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(22, 22, 22, 0.2);
  box-shadow: 0px 3px 4px rgb(0 0 0 / 25%);
  backdrop-filter: blur(5px);
  border-radius: 7px;
  svg {
    background: none !important;
  }
  .carousel {
    width: 100%;

    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      display: flex;
      justify-content: center;
    }
  }
  .hideBlock {
    position: absolute;
    right: 10;
    top: 0;
    color: white;
    text-align: right;
    cursor: pointer;
  }

  .mapMetricsMenu {
    max-width: 270px !important;

    .nav-link {
      width: 125px !important;
    }
  }
}
.expandedView_popup {
  max-height: 150px;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  background: rgba(22, 22, 22, 0.2);
  box-shadow: 0px 3px 4px rgb(0 0 0 / 25%);
  backdrop-filter: blur(5px);
  border-radius: 7px;
  svg {
    background: none !important;
  }
  .carousel {
    width: 100%;

    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      display: flex;
      justify-content: center;
    }
  }
  .hideBlock {
    position: absolute;
    right: 10;
    top: 0;
    color: white;
    text-align: right;
    cursor: pointer;
  }

  .mapMetricsMenu {
    max-width: 270px !important;

    .nav-link {
      width: 125px !important;
    }
  }
}

.carouselitem {
  display: inline-flex;
  color: white;
  flex: 1 1 15%;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  .slick-list {
    width: 100%;
  }

  .slick-slider {
    display: flex !important;
    width: 92% !important;
    margin: auto;
  }
  .mapMetricBlock {
    width: 17%;
    border-left: 1px solid grey;
    .metricData {
      display: flex;
    }
    .mapPillmetric {
      background: #000000;
      min-width: 3rem;
      height: 1.5rem;
      border-radius: 25px;
      padding-left: 4px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.iconTitle {
  height: 32px;
  background: rgba(245, 245, 245, 0.7);
  border-radius: 16px;
  color: #000000;
  .card-body {
    padding-top: 3px;
    padding-left: 30px;
    .iconBadge {
      margin-top: 15px;
      background: linear-gradient(204.79deg, #2a2956 8.67%, #030000 84.78%);
      transform: matrix(-0.95, 0.32, 0.32, 0.95, 0, 0);
      img {
        width: 24px;
        height: 22px;
      }
    }
  }
}
.refresh-text {
  color: #1ec9ff;
  font-size: 11px;
  span {
    display: flex;
    flex-wrap: nowrap;
  }
}

.hideArrow {
  .accordion-button::before,
  .accordion-button::after {
    background-image: none;
  }
}
.orderPlaceSmall-text {
  font-size: 12px;
}

.popover.bs-popover-top {
  width: auto;
}
.mapMetricInfo {
  font-style: normal;
  font-weight: 100;
  font-size: 12px;
  line-height: 12px;
  display: flex;

  img {
    height: 17px;
  }
}

.mapCountryName {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.mapMetricBlock .metricData {
  display: flex;
}

.dSpaceBTW {
  display: flex;
  justify-content: space-between;
}

.secondaryText {
  opacity: 0.8;
}
.bg-group-data-store {
  background: rgba(0, 0, 0, 0.3);
  border: 0.5px solid #69baa6;
  border-radius: 9px;
  padding: 0;
  color: white;
}
.bg-modal-store {
  background: url('../assets/images/store/worldmap.png'),
    linear-gradient(109.86deg, #224166 0.65%, #0b1d34 100%);
}
.curserLink {
  cursor: pointer;
}
.moreInfo_yesterday_store {
  color: #bc3800;
}
.moreInfo_now_store {
  color: #00760c;
}

.moreInfoCard {
  background: transparent;
}

.moreInfoHeading {
  font-size: 16px;
  color: #121212;
  background: rgba(247, 247, 247, 0.85);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 7px;
  .heading {
    font-weight: 600;
  }
  .subHeading {
    font-weight: 400;
  }
}
.moreInfo_footer_heading {
  background: rgba(247, 247, 247, 0.85);
  backdrop-filter: blur(5px);
  color: #121212;
  border-radius: 7px;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
}
.moreInfo-card-body-store {
  overflow-y: auto;
  height: 400px;
  /* identical to box height */
  background: rgba(22, 22, 22, 0.6);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 7px;
  color: #f1f1f1;
  .content {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
  }
}

.alertMessage {
  line-height: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  padding: 0rem 11rem -1rem 8rem;
  width: 42%;
  align-items: end;
  margin: -41px 305px 0px auto;
}

.modal-heading-store {
  font-style: normal;
  font-weight: 550;
  font-size: 26px;
  line-height: 38px;
  /* identical to box height */
  color: #f1f1f1;
}
.close-icon-store {
  cursor: pointer;
  float: right;
  font-size: 40px;
  font-weight: 200;
}
.moreInfoModal {
  z-index: 1070;
}
